<template>
    <div>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto fill-height" min-height="280" outlined>
                    <v-card-title>
                        Mailverlauf
                    </v-card-title>
                    <v-data-table :headers="headers" :items="$store.state.mails.allMailsArray" :items-per-page="20"
                        :search="search" class="elevation-1">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }">
                            {{ header.formatter(value) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!--<v-row>
        <pre>{{
          JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
</template>
  
<script>

export default {
    components: {
    },
    data() {
        return {
            search: "",
            headers: [
                { text: "Datum", value: "delivery.startTime.seconds", formatter: this.getDateFromTS },
                //                { text: "Id", value: "id" },
                { text: "Empfänger", value: "to" },
                { text: "Betreff", value: "message.subject" },
                { text: "Versandstatus", value: "delivery.state" },
                { text: "Serverinfo", value: "delivery", formatter: this.getServerInfo  }
            ]
        }
    },
    methods: {
        getDateFromTS(ts) {
            const timestamp = (typeof (ts) == "number") ? parseInt(ts) * 1000 : false
            if (timestamp) {
                const dateobj = (timestamp) ? new Date(timestamp) : new Date(ts)
                return dateobj.toLocaleString("de-DE")
            } else {
                return ""
            }
        },
        getServerInfo(delivery) {
            // Prüfen, ob delivery.info.response existiert
            if (delivery && delivery.info && delivery.info.response) {
                return delivery.info.response;
            } else if (delivery && delivery.error) {
                return delivery.error;
            } else {
                return "-";
            }
        }        
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store
            .dispatch("getAllMails")
            .then(() => {
                console.log("fertisch")

            })
    }
}
</script>
  
<style></style>